import { JSX, lazy } from 'react';

import { NotFound } from '@otello/features';
import { pages } from '@otello/helpers';
import { PublicLayout, ProtectedLayout } from '@otello/layouts';
import { ErrorBlock } from '@otello/ui';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const LoginPage = lazy(() =>
  import('@otello/pages/login').then((module) => ({
    default: module.default.Login,
  })),
);
const RegistrationPage = lazy(() =>
  import('@otello/pages/login').then((module) => ({
    default: module.default.RegistrationForm,
  })),
);
const ResetPage = lazy(() =>
  import('@otello/pages/login').then((module) => ({
    default: module.default.Reset,
  })),
);

const MainPage = lazy(() =>
  import('@otello/pages/main').then((module) => ({
    default: module.default.Main,
  })),
);

const RoomsPage = lazy(() =>
  import('@otello/pages/rooms').then((module) => ({
    default: module.default.RoomsList,
  })),
);
const RoomsViewPage = lazy(() =>
  import('@otello/pages/rooms').then((module) => ({
    default: module.default.RoomsView,
  })),
);
const RoomsCreatePage = lazy(() =>
  import('@otello/pages/rooms').then((module) => ({
    default: module.default.RoomsCreate,
  })),
);

const RatesPage = lazy(() =>
  import('@otello/pages/rates').then((module) => ({
    default: module.default.RatesList,
  })),
);
const RatesEditPage = lazy(() =>
  import('@otello/pages/rates').then((module) => ({
    default: module.default.RatesEdit,
  })),
);
const RatesCreatePage = lazy(() =>
  import('@otello/pages/rates').then((module) => ({
    default: module.default.RatesCreate,
  })),
);

const PricesPage = lazy(() =>
  import('@otello/pages/prices').then((module) => ({
    default: module.default.Prices,
  })),
);

const BookingPage = lazy(() =>
  import('@otello/pages/booking').then((module) => ({
    default: module.default.BookingList,
  })),
);

const ProfilePage = lazy(() =>
  import('@otello/pages/profile').then((module) => ({
    default: module.default.Profile,
  })),
);

const DigestPage = lazy(() =>
  import('@otello/pages/digest').then((module) => ({
    default: module.default.Digest,
  })),
);

const DiscountPage = lazy(() =>
  import('@otello/pages/discount').then((module) => ({
    default: module.default.DiscountList,
  })),
);
const DiscountCreatePage = lazy(() =>
  import('@otello/pages/discount').then((module) => ({
    default: module.default.DiscountCreate,
  })),
);

const UnavailablePage = lazy(() =>
  import('@otello/pages/unavailable').then((module) => ({
    default: module.default.Unavailable,
  })),
);

const FinancePage = lazy(() =>
  import('@otello/pages/finance').then((module) => ({
    default: module.default.Finance,
  })),
);
const FinanceDocumentsPage = lazy(() =>
  import('@otello/pages/finance').then((module) => ({
    default: module.default.FinanceDocuments,
  })),
);
/** Страницы, доступные не авторизированным пользователям */
const publicPages: { path: string; Component: JSX.Element }[] = [
  {
    path: pages.LOGIN,
    Component: <LoginPage />,
  },
  {
    path: pages.REGISTRATION,
    Component: <RegistrationPage />,
  },
  {
    path: pages.RESET,
    Component: <ResetPage />,
  },
];

/** Страницы, доступные только после входа */
const protectedPages: { path: string; Component: JSX.Element }[] = [
  {
    path: pages.MAIN,
    Component: <MainPage />,
  },
  {
    path: pages.PROFILE,
    Component: <ProfilePage />,
  },
  {
    path: pages.ROOMS,
    Component: <RoomsPage />,
  },
  {
    path: pages.ROOMS_VIEW,
    Component: <RoomsViewPage />,
  },
  {
    path: pages.ROOMS_CREATE,
    Component: <RoomsCreatePage />,
  },
  {
    path: pages.RATES,
    Component: <RatesPage />,
  },
  {
    path: pages.RATES_CREATE,
    Component: <RatesCreatePage />,
  },
  {
    path: pages.RATES_EDIT,
    Component: <RatesEditPage />,
  },
  {
    path: pages.PRICES,
    Component: <PricesPage />,
  },

  {
    path: pages.DISCOUNT,
    Component: <DiscountPage />,
  },
  {
    path: pages.DISCOUNT_CREATE,
    Component: <DiscountCreatePage />,
  },
  {
    path: pages.PRICES_DISCOUNT,
    Component: <DiscountPage />,
  },
  {
    path: pages.PRICES_DISCOUNT_CREATE,
    Component: <DiscountCreatePage />,
  },
  {
    path: pages.BOOKING,
    Component: <BookingPage />,
  },
  {
    path: pages.DIGEST,
    Component: <DigestPage />,
  },
  {
    path: pages.FINANCE,
    Component: <FinancePage />,
  },
  {
    path: pages.FINANCE_DOCUMENTS,
    Component: <FinanceDocumentsPage />,
  },
];

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PublicLayout />}>
        {publicPages.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={Component}
            errorElement={<ErrorBlock />}
          />
        ))}
      </Route>

      <Route element={<ProtectedLayout />}>
        {protectedPages.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={Component}
            errorElement={<ErrorBlock />}
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route
        path={pages.UNAVAILABLE}
        element={<UnavailablePage />}
        errorElement={<ErrorBlock />}
      />
    </Route>,
  ),
);
