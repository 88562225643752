import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { LensSolid } from '@otello/assets';
import { rem } from '@otello/helpers';

import { PaperBase } from '../../../PaperBase/PaperBase';

interface EmptyBlockProps {
  text?: string;
  subText?: string;
  sx?: SxProps;
}

export const EmptyBlock: FC<EmptyBlockProps> = ({ text, subText, sx }) => {
  return (
    <PaperBase sx={sx}>
      <Box m="auto">
        <Box display="flex" justifyContent="center">
          <LensSolid />
        </Box>
        <Typography variant="h2" mt={rem(24)} textAlign="center">
          {text ? text : 'Данные не добавлены!'}
        </Typography>
        {subText && (
          <Typography variant="body1" mt={rem(24)} textAlign="center">
            {subText}
          </Typography>
        )}
      </Box>
    </PaperBase>
  );
};
