import { useEffect, useRef } from 'react';

import {
  dispatch,
  PRICE_FILTER,
  setFilters,
  useAppSelector,
} from '@otello/store';
import { useLocation, useNavigate } from 'react-router-dom';

import { pages } from '../constants';
import { getBasePath } from '../utils';

/**
 * Хук, который следит за сменой отеля и выполняет необходимые действия
 */
export const useHotelChange = () => {
  const { active } = useAppSelector((state) => state.hotel);

  const { rooms, rates } = useAppSelector(
    (state) => state.checkerboard.filters,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const lastSelectedHotel = useRef<number>(null);

  useEffect(() => {
    const currentPage = `/${getBasePath(location.pathname)}`;

    if (!lastSelectedHotel.current) {
      lastSelectedHotel.current = active?.id ?? null;

      /** Необходимые редиректы при первичных проверках **/
      switch (currentPage) {
        case pages.PRICES_DISCOUNT:
          if (active && !active?.otelloId) {
            navigate(pages.PRICES);
          }
          break;

        default:
          break;
      }
    } else if (
      lastSelectedHotel.current &&
      lastSelectedHotel.current !== active?.id
    ) {
      lastSelectedHotel.current = active?.id ?? null;

      /** Сброс фильтров в шахматке **/
      if (rooms) {
        dispatch(setFilters({ type: PRICE_FILTER.ROOMS, filters: null }));
      }

      if (rates) {
        dispatch(setFilters({ type: PRICE_FILTER.RATES, filters: null }));
      }

      /** Необходимые редиректы при переключении отеля **/
      switch (currentPage) {
        case pages.ROOMS:
          navigate(pages.ROOMS);
          break;

        case pages.RATES:
          navigate(pages.RATES);
          break;

        case pages.PRICES_DISCOUNT:
          if (!active?.otelloId) {
            navigate(pages.PRICES);
          }
          break;

        default:
          break;
      }
    }
  }, [active?.id]);
};
