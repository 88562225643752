import { generateUtilityClasses, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, marginRoot } from '@otello/theme';
import { NavLink } from 'react-router-dom';

export const classes = generateUtilityClasses('Header-Layout', [
  'root',
  'contacts',
  'navLinkBase',
]);

export const Root = styled(Box, {
  label: classes.root,
})(({ theme: { palette, shadows, breakpoints, zIndex } }) => ({
  height: rem(120),
  display: 'flex',
  justifyContent: 'center',
  background: palette.basic.white['100'],
  padding: rem(0, marginRoot),
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: zIndex.header,
  borderBottom: `1px solid ${palette.basic.gray['20']}`,

  [breakpoints.up(BREAKPOINTS.MD)]: {
    boxShadow: shadows[2],
  },

  [breakpoints.down(BREAKPOINTS.MD)]: {
    height: rem(56),
    padding: 0,
  },
}));

export const WrapperHeaderNavigation = styled(Box, {
  label: classes.root,
})(() => ({
  height: rem(60),
  display: 'flex',
  md: {
    gap: rem(12),
  },
  alignItems: 'center',
  margin: 'auto',
}));

export const WrapperHeaderInfo = styled(Box, {
  label: classes.root,
})(() => ({
  height: rem(60),
  display: 'flex',
  alignItems: 'center',
}));

export const NavLinkBase = styled(NavLink, {
  label: classes.navLinkBase,
})<{ isMobile?: boolean }>(({ theme: { palette, typography }, isMobile }) => ({
  color: palette.basic.secondary,
  borderRadius: rem(8),
  padding: isMobile ? 0 : rem(6, 16, 6, 10),
  textDecoration: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: isMobile ? 'flex-start' : 'center',

  '&:hover': {
    background: isMobile ? 'unset' : palette.basic.gray['30'],
  },

  '& svg': {
    minWidth: 0,
    color: palette.basic.tertiary,
    marginRight: isMobile ? 0 : rem(8),
  },

  '&.active': {
    background: isMobile ? 'unset' : palette.basic.gray['30'],
    color: palette.basic.primary,
    '& svg': {
      color: palette.basic.green['90'],
    },
    '> svg': {
      color: palette.basic.green['90'],
    },
  },

  ...typography.body2,
}));

export const ContactsBoxStyled = styled(Box, {
  label: classes.contacts,
})(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: palette.basic.gray['20'],
  borderRadius: rem(12),
  height: rem(40),
  padding: rem(0, 10),
}));

export const TypographyStyled = styled(Typography)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));
