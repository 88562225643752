export type CreateRateResponse = RatesListItem;

export type ICreateRateProps = Partial<
  Omit<RatesListItem, 'id' | 'archived_from' | 'order'>
> & {
  hotelId: number | string;
};

export type IUpdateRateProps = Omit<
  RatesListItem,
  | 'id'
  | 'archived_from'
  | 'order'
  | 'related_plan'
  | 'has_bookings'
  | 'plan_update_log'
> & {
  rateId: number | string;
  hotelId: number | string;
  related_plan?: number | null;
};

export type RateInfoResponse = Omit<RatesListItem, 'room_types'> & {
  room_types: IRoomType[];
  other_room_types: IRoomType[];
};

interface IRoomType {
  id: number;
  room_type: string;
  order: number;
}

export enum PaymentMethod {
  hotel = 'hotel',
  online = 'online',
  hotel_and_online = 'hotel_and_online',
}

export enum PriceBehaviorEnum {
  increase = 'increase',
  decrease = 'decrease',
  notChange = 'not_change',
}

export enum FoodType {
  without_food = 'without_food',
  breakfast = 'breakfast',
  breakfast_dinner = 'breakfast_dinner',
  breakfast_lunch = 'breakfast_lunch',
  breakfast_lunch_dinner = 'breakfast_lunch_dinner',
  dinner = 'dinner',
  lunch_dinner = 'lunch_dinner',
  all_inclusive = 'all_inclusive',
}

export enum CancellationType {
  first_day_cancel = 'first_day_cancel',
  'non-refundable_rate' = 'non-refundable_rate',
  cancel_without_fine = 'cancel_without_fine',
  another_type = 'another_type',
}

export enum CancellationFee {
  one_day_price = 'one_day_price',
  entire_price_booking = 'entire_price_booking',
  half_price_booking = 'half_price_booking',
}

export type RatesListItem = {
  id: number;
  /**
   * Название тарифа
   */
  plan_name: string;
  /**
   * Сокращенное название тарифа
   */
  plan_short_name: string;
  /**
   * Способ оплаты
   */
  payment_method: PaymentMethod;
  /**
   * Тип питания
   */
  food_type: FoodType;
  /**
   * Тип отмены
   */
  cancellation_type: CancellationType;
  /**
   * Время до заезда
   */
  days_before_check_in: number | null;
  /**
   * Штраф за отмену
   */
  cancellation_fee: CancellationFee | null;
  /**
   * Минимальное кол-во суток проживания
   */
  min_night_cnt: number;
  /**
   * Открыть продажи за [дней] до заезда
   */
  open_days_before: number | null;
  /**
   * Открыть продажи за [часов] до заезда
   */
  open_hours_before: number | null;
  /**
   * Закрыть продажи за [дней] до заезда
   */
  close_days_before: number | null;
  /**
   * Закрыть продажи за [часов] до заезда
   */
  close_hours_before: number | null;
  /**
   * Процент изменения цены
   */
  related_change_perc: number | null;
  /**
   * В архиве с [дата]
   */
  archived_from: string | null;
  /**
   * Подключенные номера
   */
  room_types: Array<number>;
  /**
   * Наличие брони по тарифу
   */
  has_bookings: boolean;
  order: number;
  /**
   * Является ли тариф родительским для других
   */
  is_parent: boolean;
  /**
   * Имеет ли тариф родителя
   */
  has_parent: boolean;
  /**
   * id родителя
   */
  parent: number | null;
  /**
   * Информация о измененных полях
   */
  plan_update_log: RatePlanUpdateLog | null;
};

export const ratePlanUpdateField = {
  plan_names: 'Наименование тарифа',
  payment_methods: 'Способ оплаты',
  food_types: 'Тип питания',
  cancellation_restrictions: 'Тип отмены',
  length_of_stay_restrictions: 'Ограничения по длительности проживания',
  opening_before_arrival_restrictions:
    'Ограничения по открытию тарифа до заезда',
  parentness_restrictions: 'Зависимый тариф',
  room_types: 'Номера',
} as const;

export type RatePlanUpdateLog = {
  [key in keyof typeof ratePlanUpdateField]: unknown;
} & { created: Date; description: string };

export type RatesListResponse = Array<RatesListItem>;

export type RateParentPlansResponse = {
  id: number;
  plan_short_name: string;
  plan_name: string;
  is_parent: boolean;
  has_parent: boolean;
}[];

export type RateParentPlansQueryParams = {
  excludedRateId: number | string;
  hotelId: number | string;
};
