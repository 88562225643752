import {
  Box,
  BoxProps,
  MenuItem,
  MenuItemProps,
  Typography,
} from '@mui/material';
import { Hotel } from '@otello/assets';
import { rem } from '@otello/helpers';

import { ItemBoxStyled } from '../../Select.styles';
import { Item, SelectBaseProps } from '../../SelectBase/Select';

interface HotelItemProps<T extends Item> {
  item: T;
  isActive?: boolean;
  isInput?: boolean;
  component?: BoxProps['component'];
}

export const HotelItem = <T extends Item>(
  props: HotelItemProps<T> & MenuItemProps & BoxProps & SelectBaseProps<T>,
) => {
  const {
    sx,
    component,
    item,
    titleKey = 'title',
    isActive = false,
    isInput = false,
  } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <ItemBoxStyled {...props} sx={sx} component={component ?? MenuItem}>
      <Box
        display="flex"
        alignItems="center"
        mr={rem(6)}
        width={rem(24)}
        height={rem(24)}
        color={isActive ? 'basic.green.90' : 'basic.tertiary'}
      >
        <Hotel />
      </Box>

      <Box height={rem(32)} width="100%" display="grid">
        <Box
          display="flex"
          alignItems="center"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Typography
            display="block"
            variant="caption1"
            color="basic.primary"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            className={isInput ? '' : 'highlight'}
          >
            {(item[titleKey] as string) ?? ''}
          </Typography>

          <Typography
            key={item.id}
            noWrap
            flexShrink={0}
            ml={rem(8)}
            variant="caption2"
            color="basic.secondary"
            className={isInput ? '' : 'highlight'}
          >
            ID {item.id}
          </Typography>
        </Box>

        <Typography
          display="block"
          variant="caption2"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          className={isInput ? '' : 'highlight'}
        >
          {item.address ?? 'Адрес отсутствует'}
        </Typography>
      </Box>
    </ItemBoxStyled>
  );
};
