import { lazy } from 'react';

export const createPreloadableComponent = (importFunc: () => Promise<any>) => {
  let loadPromise: Promise<any> | null = null;

  const LazyComponent = lazy(importFunc);

  const preload = () => {
    if (!loadPromise) {
      loadPromise = importFunc();
    }

    return loadPromise;
  };

  const PreloadableComponent = (props: any) => <LazyComponent {...props} />;

  PreloadableComponent.preload = preload;

  return PreloadableComponent;
};
