import {
  Bed,
  Book,
  Calendar,
  Info,
  LightningSolid,
  Luggage,
  Pass,
} from '@otello/assets';
import {
  BASE_PAGES,
  createPreloadableComponent,
  pages,
  rem,
} from '@otello/helpers';

const RoomsPage = createPreloadableComponent(
  () => import('@otello/pages/rooms'),
);
const MainPage = createPreloadableComponent(() => import('@otello/pages/main'));
const RatesPage = createPreloadableComponent(
  () => import('@otello/pages/rates'),
);
const BookingPage = createPreloadableComponent(
  () => import('@otello/pages/booking'),
);
const PricesPage = createPreloadableComponent(
  () => import('@otello/pages/prices'),
);
const FinancePage = createPreloadableComponent(
  () => import('@otello/pages/finance'),
);

/** Отдаем меню навигации в нужном виде (мобайл/десктоп) **/
export const navRouter = ({
  isMobile = false,
  isExtranet = false,
}: {
  isMobile?: boolean;
  isExtranet: boolean;
}) => {
  const iconSize = {
    width: rem(isMobile ? 20 : 24),
    height: rem(isMobile ? 20 : 24),
  };

  const checkerboardPageName = isMobile ? 'Цены' : 'Доступность и цены';
  const informationPageName = isMobile ? 'Об отеле' : 'Информация';

  const baseNavigation = [
    {
      path: pages.MAIN,
      name: informationPageName,
      icon: <Info {...iconSize} />,
      loadablePages: MainPage,
      attributeValue: 'main',
    },
    {
      path: pages.ROOMS,
      name: 'Номера',
      icon: <Bed {...iconSize} />,
      loadablePages: RoomsPage,
      attributeValue: BASE_PAGES.ROOMS,
    },
    {
      path: pages.RATES,
      name: 'Тарифы',
      icon: <Book {...iconSize} />,
      loadablePages: RatesPage,
      attributeValue: BASE_PAGES.RATES,
    },
    {
      path: pages.PRICES,
      name: checkerboardPageName,
      icon: <Calendar {...iconSize} />,
      loadablePages: PricesPage,
      attributeValue: BASE_PAGES.PRICES,
    },
    {
      path: pages.BOOKING,
      name: 'Бронирования',
      icon: <Luggage {...iconSize} />,
      loadablePages: BookingPage,
      attributeValue: BASE_PAGES.BOOKING,
    },
    {
      path: pages.FINANCE,
      name: 'Финансы',
      icon: <Pass {...iconSize} />,
      loadablePages: FinancePage,
      attributeValue: BASE_PAGES.FINANCE,
    },
  ];

  const extranetNavigation = [
    {
      path: pages.MAIN,
      name: informationPageName,
      icon: <Info {...iconSize} />,
      loadablePages: MainPage,
      attributeValue: 'main',
    },
    {
      path: pages.BOOKING,
      name: 'Бронирования',
      icon: <Luggage {...iconSize} />,
      loadablePages: BookingPage,
      attributeValue: BASE_PAGES.BOOKING,
    },
    {
      path: pages.FINANCE,
      name: 'Финансы',
      icon: <Pass {...iconSize} />,
      loadablePages: FinancePage,
      attributeValue: BASE_PAGES.BOOKING,
    },
    {
      path: pages.DISCOUNT,
      name: 'Акции',
      icon: <LightningSolid {...iconSize} />,
      loadablePages: BookingPage,
      attributeValue: BASE_PAGES.BOOKING,
    },
    {
      path: pages.ROOMS,
      name: 'Номера',
      icon: <Bed {...iconSize} />,
      loadablePages: RoomsPage,
      attributeValue: BASE_PAGES.ROOMS,
      isInProgress: true,
    },
    {
      path: pages.RATES,
      name: 'Тарифы',
      icon: <Book {...iconSize} />,
      loadablePages: RatesPage,
      attributeValue: BASE_PAGES.RATES,
      isInProgress: true,
    },
  ];

  return isExtranet ? extranetNavigation : baseNavigation;
};
