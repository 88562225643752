import { MouseEvent, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useGetUserQuery } from '@otello/api';
import { Dropdown, Logout, Profile, User } from '@otello/assets';
import { createPreloadableComponent, pages, rem } from '@otello/helpers';
import { logout, useAppDispatch } from '@otello/store';
import { themePalette as palette } from '@otello/theme';
import {
  ButtonBase,
  DataLoader,
  MenuBase,
  MenuItemBase,
  MenuItemBox,
} from '@otello/ui';
import { useNavigate } from 'react-router-dom';

import { TypographyStyled } from '../../Header.styles';

const ProfilePage = createPreloadableComponent(() =>
  import('@otello/pages/profile').then((module) => ({
    default: module.default.Profile,
  })),
);

export const MenuUser = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data, isLoading: isUserLoading } = useGetUserQuery(null);

  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const toProfile = () => {
    onClose();
    navigate(pages.PROFILE);
  };

  const preloadProfile = () => {
    ProfilePage.preload();
  };

  return (
    <>
      <DataLoader
        data={data ?? {}}
        isLoading={isUserLoading}
        height="60%"
        width="20%"
      >
        {() => (
          <ButtonBase
            data-cy="user_menu_button"
            onClick={handleClick}
            sx={{
              display: 'flex',
              textAlign: 'left',
              justifyContent: 'flex-start',
              width: rem(220),
              height: rem(42),
              padding: rem(8),
              background: palette.basic.gray['30'],
              '&:hover': {
                background: palette.basic.gray['40'],
              },
            }}
          >
            <User
              width={rem(20)}
              height={rem(20)}
              color={palette.basic.primary}
            />
            <Box ml={rem(8)} display="flex" flexDirection="column" flexGrow="1">
              {data?.username && (
                <TypographyStyled
                  width={rem(120)}
                  variant="caption1"
                  color="basic.primary"
                >
                  {data.username}
                </TypographyStyled>
              )}
              {data?.email && (
                <TypographyStyled
                  width={rem(120)}
                  variant="caption2"
                  color="basic.secondary"
                >
                  {data.email}
                </TypographyStyled>
              )}

              {!data?.email && !data?.username && (
                <TypographyStyled
                  width={rem(120)}
                  variant="caption2"
                  color="basic.secondary"
                >
                  Нет данных
                </TypographyStyled>
              )}
            </Box>
            <Dropdown
              width={rem(20)}
              height={rem(20)}
              color={palette.basic.secondary}
            />
          </ButtonBase>
        )}
      </DataLoader>
      <MenuBase anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItemBase
          data-cy="user_profile_button"
          onClick={toProfile}
          onMouseOver={preloadProfile}
        >
          <MenuItemBox>
            <Profile width={rem(20)} height={rem(20)} />
            <Typography color="basic.secondary" ml={rem(8)} variant="body1">
              Профиль
            </Typography>
          </MenuItemBox>
        </MenuItemBase>
        <MenuItemBase
          data-cy="user_exit_button"
          onClick={() => dispatch(logout())}
        >
          <MenuItemBox>
            <Logout width={rem(20)} height={rem(20)} />
            <Typography color="basic.secondary" ml={rem(8)} variant="body1">
              Выход
            </Typography>
          </MenuItemBox>
        </MenuItemBase>
      </MenuBase>
    </>
  );
};
