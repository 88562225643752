import { StrictMode } from 'react';

import { store } from '@otello/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from './app/app';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
);
