import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { More } from '@otello/assets';
import { getBasePath, rem, useToggle, withPreload } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { Drawer } from '@otello/ui';

import { NavLinkBase } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';

interface NavigationMoreProps {
  menu: ReturnType<typeof navRouter>;
}

const NavLinkWithPreload = withPreload(NavLinkBase);

export const NavigationMore: FC<NavigationMoreProps> = ({ menu }) => {
  const { active } = useAppSelector((store) => store?.hotel);

  const [isOpen, modalActions] = useToggle(false);

  if (!active) {
    return null;
  }

  return (
    <>
      <Box
        width={rem(64)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={modalActions.open}
      >
        <Box color="basic.tertiary">
          <More width={rem(20)} height={rem(20)} />
        </Box>

        <Typography
          variant="caption2"
          maxWidth={rem(60)}
          textOverflow="ellipsis"
          overflow="hidden"
          color="basic.secondary"
        >
          Ещё
        </Typography>
      </Box>

      <Drawer
        open={isOpen}
        onClose={modalActions.close}
        anchor="bottom"
        isShowCloseIcon
        isShowBackButton={false}
      >
        <Grid container flexDirection="column" gap={rem(32)}>
          <Grid>
            <Typography variant="h2">Ещё</Typography>
          </Grid>
          {menu.map(({ path, name, icon, loadablePages }) => (
            <Grid key={path} display="flex">
              <NavLinkWithPreload
                onClick={modalActions.close}
                data-cy={`${getBasePath(path)}_link`}
                to={path}
                isMobile
                lazyComponent={loadablePages}
              >
                <Box display="flex" alignItems="center" gap={rem(8)}>
                  <Box display="flex">{icon}</Box>

                  <Typography
                    variant="caption1"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {name}
                  </Typography>
                </Box>
              </NavLinkWithPreload>
            </Grid>
          ))}
        </Grid>
      </Drawer>
    </>
  );
};
