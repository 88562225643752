import {
  CSSProperties,
  TypographyOptions,
} from '@mui/material/styles/createTypography';
import { rem } from '@otello/helpers';

import { defaultFamily } from './fonts';

const defaultStyles = {
  body1: {
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: rem(24),
  },
  body2: {
    fontSize: rem(14),
    fontWeight: 400,
    lineHeight: rem(20),
  },
  caption1: {
    fontSize: rem(13),
    fontWeight: 400,
    lineHeight: rem(18),
  },
  caption2: {
    fontSize: rem(12),
    fontWeight: 400,
    lineHeight: rem(16),
  },
};
interface ExtendedTypographyOptions extends TypographyOptions {
  body1: CSSProperties;
  body1_medium: CSSProperties;
  body1_semi: CSSProperties;
  body2_medium: CSSProperties;
  body2_semi: CSSProperties;
  caption1: CSSProperties;
  caption1_medium: CSSProperties;
  caption1_semi: CSSProperties;
  caption1_bold: CSSProperties;
  caption2: CSSProperties;
  caption2_medium: CSSProperties;
  caption2_semi: CSSProperties;
  caption2_bold: CSSProperties;
}

export const typography: ExtendedTypographyOptions = {
  fontFamily: defaultFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: rem(24),
    lineHeight: rem(32),
    fontWeight: 700,
  },
  h2: {
    fontSize: rem(20),
    lineHeight: rem(24),
    fontWeight: 700,
    letterSpacing: '0.015em',
  },
  body1: defaultStyles.body1,
  body1_medium: { ...defaultStyles.body1, fontWeight: 500 },
  body1_semi: { ...defaultStyles.body1, fontWeight: 600 },
  body2: defaultStyles.body2,
  body2_medium: { ...defaultStyles.body2, fontWeight: 500 },
  body2_semi: { ...defaultStyles.body2, fontWeight: 600 },
  caption1: defaultStyles.caption1,
  caption1_medium: { ...defaultStyles.caption1, fontWeight: 500 },
  caption1_semi: { ...defaultStyles.caption1, fontWeight: 600 },
  caption1_bold: { ...defaultStyles.caption1, fontWeight: 700 },
  caption2: defaultStyles.caption2,
  caption2_medium: { ...defaultStyles.caption2, fontWeight: 500 },
  caption2_semi: { ...defaultStyles.caption2, fontWeight: 600 },
  caption2_bold: { ...defaultStyles.caption2, fontWeight: 700 },
};
