import { FC } from 'react';

import { Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { useGetManagerContactsQuery } from '@otello/api';
import { Bell, More, User } from '@otello/assets';
import { DigestBadge } from '@otello/features';
import {
  BASE_PAGES,
  createPreloadableComponent,
  getFormattedPhone,
  pages,
  rem,
  useToggle,
} from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { themePalette } from '@otello/theme';
import { ButtonBase, DataLoader, Drawer, SelectMenu } from '@otello/ui';
import { useNavigate } from 'react-router-dom';

import { Contact } from './features/Contact/Contact';
import { HeaderBaseProps } from './Header';
import { ContactTypes } from './helpers/types';

const DigestPage = createPreloadableComponent(() =>
  import('@otello/pages/digest').then((module) => ({
    default: module.default.Digest,
  })),
);

export const HeaderMobile: FC<HeaderBaseProps> = ({
  hotelsList,
  handleHotelChange,
  isNeedBackendSearch,
  handleSearchHotel,
  isLoading,
  isListLoading,
}) => {
  const active = useAppSelector((state) => state.hotel.active);

  const [isContactsModalOpen, contactsModalActions] = useToggle(false);

  const navigate = useNavigate();

  const {
    data: managerContacts,
    isLoading: managerContactsIsLoading,
    isUninitialized: isManagerContactsUninitialized,
  } = useGetManagerContactsQuery(
    {
      hotelId: String(active?.id),
    },
    {
      skip: !active?.id,
    },
  );

  const handleContactsClick = () => contactsModalActions.open();
  const handleProfileClick = () => navigate(pages.PROFILE);

  const handlePreloadDigest = () => {
    DigestPage.preload();
  };

  const handleDigestClick = () => {
    navigate(BASE_PAGES.DIGEST);
    contactsModalActions.close();
  };

  return (
    <Grid container width="100%" p={rem(8, 20)} flexWrap="nowrap" gap={rem(8)}>
      <Grid>
        <SelectMenu
          data-cy="set_hotel_select"
          itemType="hotel"
          isAutocomplete
          hasBorder={false}
          value={active?.id}
          onChange={handleHotelChange}
          height={rem(40)}
          menuWidth={rem(460)}
          isLoading={isLoading}
          isListLoading={isListLoading}
          searchKeys={['id', 'title', 'address']}
          items={hotelsList}
          sxBox={{
            minWidth: rem(184),
            backgroundColor: themePalette.basic.gray['30'],
            padding: rem(0, 8),
          }}
          handleSearch={
            isNeedBackendSearch ? (text) => handleSearchHotel(text) : undefined
          }
        />
      </Grid>

      <Grid marginLeft="auto">
        <ButtonBase
          data-cy="user_profile_button"
          onClick={handleProfileClick}
          sx={{
            width: rem(40),
            height: rem(40),
            padding: 'unset',
            background: 'none',
            color: 'basic.primary',
            '&:hover': {
              background: 'none',
            },
          }}
        >
          <User width={rem(34)} height={rem(34)} />
        </ButtonBase>
      </Grid>
      <Grid
        width={rem(40)}
        height={rem(40)}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <DigestBadge>
          <ButtonBase
            onClick={handleContactsClick}
            data-cy="contacts_info_button"
            sx={{
              width: rem(34),
              height: rem(34),
              padding: 'unset',
              color: 'basic.secondary',
              borderRadius: '40% !important',
              backgroundColor: 'basic.gray.30',

              '&:hover': {
                backgroundColor: 'basic.gray.30',
              },
            }}
          >
            <More width={rem(24)} height={rem(24)} />
          </ButtonBase>
        </DigestBadge>
      </Grid>

      <Drawer
        open={isContactsModalOpen}
        onClose={contactsModalActions.close}
        anchor="bottom"
        sx={{ padding: rem(20) }}
        isShowCloseIcon
        closeButtonAttributeValue="contacts_close_button"
      >
        <Grid container gap={rem(24)} flexDirection="column">
          <Grid>
            <Typography variant="h2" mb={rem(16)}>
              Поддержка
            </Typography>
            <Contact
              type={ContactTypes.PHONE}
              title="Позвонить в поддержку"
              contact="+7 (800) 200 15 21"
            />
            <Contact
              type={ContactTypes.MAIL}
              title="Написать в поддержку"
              contact="+7 (800) 200 15 21"
            />

            <DataLoader
              data={managerContacts}
              width="40%"
              isLoading={
                managerContactsIsLoading || isManagerContactsUninitialized
              }
              sx={{ m: rem(16, 0) }}
            >
              {({ phone }) => (
                <Contact
                  type={ContactTypes.PHONE}
                  title="Менеджер региона"
                  contact={getFormattedPhone(phone)}
                />
              )}
            </DataLoader>

            <Contact
              type={ContactTypes.TELEGRAM}
              title="Обратная связь"
              contact="@otellosupport"
            />
          </Grid>

          <Grid>
            <Typography variant="h2">Дайджест обновлений</Typography>
            <ButtonBase
              data-cy="digest_button"
              size="small"
              variant="text"
              startIcon={
                <DigestBadge>
                  <Bell />
                </DigestBadge>
              }
              onClick={handleDigestClick}
              onMouseOver={handlePreloadDigest}
              sx={{
                justifyContent: 'start',
                padding: 0,
                width: 'max-content',
                mt: rem(16),
              }}
            >
              Перейти к новостям
            </ButtonBase>
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};
