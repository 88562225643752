import { FC } from 'react';

import { Box, Grid, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Alert } from '@otello/assets';
import { isErrorWithDataString, rem } from '@otello/helpers';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface ErrorBlockProps {
  error?: FetchBaseQueryError | SerializedError | { data: string };
  sx?: SxProps<Theme>;
  xs?: number;
}

export const ErrorBlock: FC<ErrorBlockProps> = ({ error, sx, xs }) => {
  if (!isErrorWithDataString(error) || !error.data) {
    return null;
  }

  return (
    <Grid item xs={xs || 12} sx={sx} maxHeight={rem(420)} overflow="auto">
      <Box display="flex" alignItems="center" color="basic.red.90">
        <Box display="flex" alignItems="center">
          <Alert width={rem(24)} height={rem(24)} />
        </Box>

        <Typography data-cy="error-message" variant="body1" ml={rem(10)}>
          {error.data}
        </Typography>
      </Box>
    </Grid>
  );
};
