/// <reference types="vite/client" />

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { GlobalState, TokensResponse } from '../types';

import { HTTP_METHODS, logout, setTokens, Tokens } from './slices';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env['VITE_BASE_BACKEND_SERVER']}/`,
  headers: {
    'client-name': 'OtelloLK',
  },
  prepareHeaders: (headers, { getState }) => {
    const { access_token } = (getState() as GlobalState).tokens.tokens;

    if (access_token) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }

    return headers;
  },
});

const refreshQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refresh_token = localStorage.getItem(Tokens.refresh_token);

        if (refresh_token) {
          const refreshResult = (await baseQuery(
            {
              url: 'auth/token_refresh/',
              method: HTTP_METHODS.POST,
              body: { refresh: refresh_token },
              credentials: 'include',
            },
            api,
            extraOptions,
          )) as unknown as { data: TokensResponse };

          if (refreshResult && refreshResult.data) {
            api.dispatch(
              setTokens({
                tokens: {
                  access_token: refreshResult.data.access,
                  refresh_token: refreshResult.data.refresh,
                },
              }),
            );

            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logout());
          }
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: refreshQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Hotel',
    'RoomList',
    'RoomInfo',
    'HotelList',
    'ParentsRates',
    'RatesList',
    'BookingList',
    'CheckerboardTable',
    'RoomRates',
    'HotelAllowPublish',
    'BookingItem',
    'CheckerboardPriceModal',
    'HotelUnpublishedRecords',
    'DiscountList',
    'DiscountCompoundList',
  ],
});
