import { generateUtilityClasses, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import { rem } from '@otello/helpers';
import { BREAKPOINTS } from '@otello/theme';

export const classes = generateUtilityClasses('Paper', ['root']);

export const PaperStyled = styled(Paper, {
  label: classes.root,
})(({ theme: { palette, breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: palette.basic.white['100'],
  padding: rem(24),
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 0,

  [breakpoints.up(BREAKPOINTS.MD)]: {
    borderRadius: rem(20),
  },
}));
