import { FC, useMemo } from 'react';

import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { getBasePath, rem, withPreload } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { themePalette } from '@otello/theme';

import { NavLinkBase } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';
import { NavigationMore } from '../NavigationMore/NavigationMore';

const NavLinkWithPreload = withPreload(NavLinkBase);

export const NavigationMobile: FC = () => {
  const isViewFullMenu = useMediaQuery('(min-width: 500px)');
  const active = useAppSelector((state) => state.hotel.active);

  const isExtranet = !!active?.is_extranet;

  const mainMenuItems = navRouter({
    isMobile: true,
    isExtranet: !!active?.is_extranet,
  }).slice(0, 4);

  const restMenuItems = navRouter({
    isMobile: true,
    isExtranet: !!active?.is_extranet,
  }).slice(4);

  const MenuItems = useMemo(() => {
    return (
      isViewFullMenu ? navRouter({ isMobile: true, isExtranet }) : mainMenuItems
    ).map(({ path, name, icon, loadablePages }) => (
      <Grid key={path} flexDirection="column">
        {!active ? (
          <Box paddingTop={rem(10)}>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={rem(26)}
              width={rem(80)}
              sx={{ alignSenf: 'center', flexShrink: 0, borderRadius: rem(20) }}
            />
          </Box>
        ) : (
          <NavLinkWithPreload
            lazyComponent={loadablePages}
            data-cy={`${getBasePath(path)}_link`}
            to={path}
            isMobile
          >
            <Box
              width={rem(64)}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={rem(8)}
            >
              <Box display="flex">{icon}</Box>

              <Typography
                variant="caption2"
                maxWidth={rem(60)}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {name}
              </Typography>
            </Box>
          </NavLinkWithPreload>
        )}
      </Grid>
    ));
  }, [isViewFullMenu, isExtranet, active]);

  if (!isViewFullMenu) {
    return (
      <Grid
        component="nav"
        container
        height="100%"
        justifyContent="space-evenly"
        alignItems="start"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          height: rem(60),
          backgroundColor: 'basic.white.100',
          borderTop: `1px solid ${themePalette.basic.gray['20']}`,
          padding: rem(6, 0, 0),
        }}
      >
        {MenuItems}

        <NavigationMore menu={restMenuItems} />
      </Grid>
    );
  }

  return (
    <Grid
      component="nav"
      container
      height="100%"
      justifyContent="space-evenly"
      alignItems="start"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        height: rem(60),
        backgroundColor: 'basic.white.100',
        borderTop: `1px solid ${themePalette.basic.gray['20']}`,
        padding: rem(6, 0, 0),
      }}
    >
      {MenuItems}
    </Grid>
  );
};
