import { ComponentProps, ComponentType, FC, JSX, ReactNode } from 'react';

type PreloadableLinkProps<C extends ComponentType<P>, P = ComponentProps<C>> = {
  lazyComponent: {
    (props: unknown): JSX.Element;
    preload: () => Promise<unknown>;
  };
  children?: ReactNode;
} & P;

export const withPreload = <C extends ComponentType<P>, P = ComponentProps<C>>(
  Component: C,
) => {
  const ComponentWithPreload: FC<PreloadableLinkProps<C, P>> = ({
    lazyComponent,
    children,
    ...props
  }) => {
    const handleMouseOver = () => {
      lazyComponent.preload();
    };

    return (
      // TODO: Исправить типизацию
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      <Component {...props} onMouseOver={handleMouseOver}>
        {children && children}
      </Component>
    );
  };

  return ComponentWithPreload;
};
